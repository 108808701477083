<style src="./footer.component.scss" lang="scss"/>
<template>
  <footer class="footer">
    <div class="container v-container">
      <div class="row">
        <div class="col-3">
          <div class="footer__logo text-center">
            <div class="footer__logo-wrapper">
              <img src="@/assets/images/commons/logo-footer.svg" alt="">
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-12">
          <footer-contact-component/>
        </div>
        <div class="col-lg-3 col-8">
          <footer-menu-component/>
        </div>
        <div class="col-lg-2 col-4">
          <footer-action-component/>
        </div>
      </div>
    </div>
  </footer>
</template>
<script src="./footer.component.js"/>