import {mapGetters} from "vuex";

export default {
    name: 'footer-menu-component',
    computed: {
        ...mapGetters({
            local: '_getLocal'
        }),
        menu() {
            return [
                {
                    id: 1,
                    name: this.local.footer.mainPage,
                    link: 'home'
                },
                {
                    id: 2,
                    name: this.local.footer.about,
                    link: 'about'
                },
                {
                    id: 3,
                    name: this.local.footer.contact,
                    link: 'contact'
                },
                {
                    id: 5,
                    name: this.local.footer.project,
                    link: 'projects'
                },
                {
                    id: 4,
                    name: this.local.footer.financialReport,
                    link: 'financial-report'
                }
            ]
        },
        isPage() {
            return this.$route.name
        }
    },
}