import db from './../assets/db/db.json';
export default {
    _getContact(state) {
        return state.contact;
    },
    _getLocal(state) {
        return db[state.language];
    },

};
