export default {
    _getStatistic(state) {
        return state.reportStatistics;
    },
    _getLeadHomeHeader(state) {
        return state.leadHomeHeader;
    },
    _getLeadWork(state) {
        return state.leadWorks;
    },
    _getFeatures(state) {
        return state.features;
    },
};
