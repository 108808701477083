import axios from 'axios';
import api from '@/core/api';
import {ProjectsModel} from '@/views/projects/models/project.model';

export default {
    getProject({commit}, id) {
        const language = localStorage.getItem('language');
        return axios
            .get(`${api.projects}/${id}`)
            .then((res) => {
                commit('SET_PROJECT',  new ProjectsModel(res.data.project, language));
                return true;
            })
            .catch(() => {
                return false;
            });
    },
};
