export default class StatisticModel {
    constructor(item) {
        this._setProjectCount(item);
        this._setBuildingCount(item);
        this._setCustomerCount(item);
    }

    /**
     * Set ProjectCount
     * @param finishedProjectCount
     * @private
     */


    _setProjectCount({finishedProjectCount}) {
        this.projectCount = new Intl.NumberFormat().format(finishedProjectCount);
    }

    /**
     * Set buildingCount
     * @param finishedBuildingCount
     * @private
     */

    _setBuildingCount({finishedBuildingCount}) {
        this.buildingCount = new Intl.NumberFormat().format(finishedBuildingCount);
    }

    /**
     * Set customerCount
     * @param customerCount
     * @private
     */

    _setCustomerCount({customerCount}) {
        this.customerCount = new Intl.NumberFormat().format(customerCount);
    }
}
