import {createStore} from 'vuex';
import createPersistedState from "vuex-persistedstate";
import state from './state';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';
import modules from './modules';
import SecureLS from "secure-ls";

let ls = new SecureLS({isCompression: false});
const store = createStore({
    state,
    getters,
    mutations,
    actions,
    modules,
    plugins: [
        createPersistedState({
            storage: {
                getItem: (key) => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: (key) => ls.remove(key),
            },
        }),],
});

export default store;
