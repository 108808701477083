<style src="./footer-contact.component.scss" lang="scss"/>
<template>
  <div class="footer-contact">
    <div class="footer-contact__info">
      <address>
        {{ contacts.location }}
      </address>
    </div>
    <div class="footer-contact__info mt-76">
      <a :href="`tel:{contacts.phone}`">{{ contacts.phone }}</a>
    </div>
    <div class="footer-contact__info mt-26">
      <a :href="`mailto: ${contacts.email}`">{{ contacts.email }}</a>
    </div>
  </div>
</template>
<script src="./footer-contact.component.js"/>