<style src="./footer-action.component.scss" lang="scss"/>
<template>
  <div class="footer-action">
    <div class="footer-action__up">
      <div
          @click="scrollUp"
          class="footer-action__arrow text-center">
        <img src="@/assets/images/icons/arrow.svg" alt="arrow">
      </div>
    </div>
    <div class="footer-action__emblem d-none d-lg-block"></div>
  </div>
</template>
<script src="./footer-action.component.js"/>