import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import "@/assets/styles/index.scss";
import './core/interceptors';
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.min.css";
import VPagination from "@hennge/vue3-pagination";

const app = createApp(App);
app.component('v-pagination', VPagination);
app.component('swiper', Swiper);
app.component('swiper-slide', SwiperSlide);
app.use(store).use(router).mount('#app');
