import axios from 'axios';
import api from '@/core/api';
import {FinancialReportModel} from "@/views/financial-report/models/financialReport.model";

export default {
    getFinancialReport({commit}) {
        const language = localStorage.getItem("language");
        return axios
            .get(api.financialReports)
            .then((res) => {
                commit('SET_FINANCIAL_REPORT', res.data.financialReport.items.map(item => {
                    return new FinancialReportModel(item, language);
                }));
                return true;
            })
            .catch(() => {
                return false;
            });
    },
};
