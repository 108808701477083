export default {
    _getAbout(state) {
        return state.about;
    },
    _getLeadAbout(state) {
        return state.leadsAbout;
    },

    _getWorkSkills(state) {
        return state.workSkills;
    },
}
