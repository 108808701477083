export class ProjectsModel {
    id = null;
    title = '';
    description = '';
    status = '';
    customer = '';
    address = '';
    name = '';
    finished = false;
    fileUrl = ''
    fileUrls = ''

    constructor(item, language) {
        this._setId(item);
        this._setTitle(item, language);
        this._setDescription(item, language);
        this._setStatus(item, language);
        this._setCustomer(item, language);
        this._setAddress(item, language);
        this._setName(item, language);
        this._setFinished(item);
        this._setFileUrl(item);
        this._setFileUrls(item);
    }

    /**
     * Set id
     * @param id
     * @private
     */


    _setId({id}) {
        this.id = id;
    }


    /**
     * Set Title
     * @param item
     * @param language
     * @private
     */

    _setTitle(item, language) {
        const key = "title_" + language;
        this.title = item[key];
    }

    /**
     * Set description
     * @param item
     * @param language
     * @private
     */

    _setDescription(item, language) {
        const key = "description_" + language;
        this.description = item[key];
    }

    _setStatus(item, language) {
        const key = "status_" + language;
        this.status = item[key];
    }

    _setCustomer(item, language) {
        const key = "customer_" + language;
        this.customer = item[key];
    }

    _setAddress(item, language) {
        const key = "address_" + language;
        this.address = item[key];
    }

    _setName(item, language) {
        const key = "name_" + language;
        this.name = item[key];
    }

    _setFinished({finished}) {
        this.finished = finished;
    }

    _setFileUrl({fileUrls}) {
        this.fileUrl = fileUrls?.find((item) => {
           return  item.isMain = true;
        });
    }

    _setFileUrls({fileUrls}) {
        this.fileUrls = fileUrls.map((item) => {
            return item.link
        });
    }
}
