import axios from "axios";
// import store from './../store/index';
import router from '../router';

axios.defaults.baseURL = "https://api.marmar.az/api/v1/home/";
//
//
axios.interceptors.request.use(function (config) {
    return config;
}, function (error) {
    return Promise.reject(error);
});
//
axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const {
            response: {status},
        } = error;

        if (status === 400 || status === 404 || status === 500) {
            router.push({name: 'home'});
        }
    }
);

