import axios from 'axios';
import api from '@/core/api';
import StatisticModel from '@/views/home/models/statistic.model';
import {FeatureModel} from '@/views/home/models/feature.model';

export default {
    getStatistic({commit}) {
        return axios
            .get(api.reportStatistics)
            .then((res) => {
                commit('SET_STATISTIC', new StatisticModel(res.data.reportStatistics.items[0]));
                return true;
            })
            .catch(() => {
                return false;
            });
    },
    getFeatures({commit}) {
        const language = localStorage.getItem("language");
        return axios
            .get(api.features)
            .then((res) => {
                commit('SET_FEATURES',
                    res.data.features.items.map(item => {
                        return new FeatureModel(item, language);
                    }));
                return true;
            })
            .catch(() => {
                return false;
            });
    },
};
