import axios from 'axios';
import api from '@/core/api';
import {LeadModel} from '@/models/lead.model';
import {ContactModel} from '@/models/contact.model';

export default {
    getLanguage({commit}) {
        let lang = localStorage.getItem("language");
        if (lang) {
            commit("setLang", lang);
        } else {
            localStorage.setItem("language", 'az');
            commit("setLang", "az");
        }
        return true;
    },
    getLeads({commit}) {
        const language = localStorage.getItem("language");
        return axios
            .get(api.leads)
            .then((res) => {
                commit('SET_LEADS',
                    res.data.leads.items.map(item => {
                        return new LeadModel(item, language);
                    }));
                return true;
            })
            .catch(() => {
                return false;
            });
    },
    getContacts({commit}) {
        return axios
            .get(api.contacts)
            .then((res) => {
                commit('SET_CONTACTS', new ContactModel(res.data.contacts.items[0]));
                return true;
            })
            .catch(() => {
                return false;
            });
    },
};
