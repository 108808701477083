<style src="./header.component.scss" lang="scss"></style>
<template>
  <header class="header">
    <div class="container v-container">
      <div class="mobile-box">
        <div class="row">
          <div class="col-6">
            <div class="header__logo">
              <router-link :to="{name: 'home'}">
                <img src="@/assets/images/commons/logo.svg" alt="logo">
              </router-link>
            </div>
          </div>
          <div class="col-lg-6 d-lg-block d-none">
            <nav class="header__menu">
              <ul :class="{'header__menu-contact':isPage === 'contact' || isPage === 'projects'|| isPage === 'project-details'}">
                <li
                    v-for="item in menu"
                    :key="item.id"
                    class="header__menu-item"
                    :class="{'header__menu-item--active':item.link === isPage || (isPage === 'project-details' && item.link === 'projects') }"
                >
                  <router-link :to="{name:item.link}">
                    {{ item.name }}
                  </router-link>
                </li>
                <li>
                  <select class="header__menu-language-select" @change="changeLanguage" :value="language">
                    <option value="az">AZ</option>
                    <option value="en">EN</option>
                  </select>
                </li>
              </ul>
            </nav>
          </div>
          <div class="col-6 d-block d-lg-none text-right">
            <img
                @click="toggleMobile"
                src="@/assets/images/icons/menu-button.svg"
                alt="menu-icon">
          </div>
        </div>
      </div>

    </div>
    <nav
        :class="{'mobile__menu-active':mobile}"
        class="mobile__menu d-lg-none d-block">
      <div
          class="mobile__menu-close"
      >
        <img @click="toggleMobile" src="@/assets/images/icons/close.svg" alt="">
      </div>
      <ul>
        <li
            v-for="item in menu"
            :key="item.id"
            class="mobile__menu-item"
            @click="toggleMobile"
        >
          <router-link :to="item.link">
            {{ item.name }}
          </router-link>
        </li>
      </ul>
    </nav>
  </header>
</template>
<script src="./header.component.js"></script>
