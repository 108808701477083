export default {
    SET_LEADS(state, payload) {
        payload.forEach(item => {
            if (item.leadTypeId === 1) {
                state.home.leadHomeHeader = item;
            }
            if (item.leadTypeId === 3) {
                state.home.leadWorks = item;
            }
            if (item.leadTypeId === 2) {
                state.about.leadsAbout = item;
            }
            if (item.leadTypeId === 4) {
                state.financialReport.leadFinancialReport = item;
            }
        });
    },
    SET_CONTACTS(state, payload) {
        state.contact = payload;
    },
    setLang(state, payload) {
        state.language = payload;
    },
    setLangManual(state, payload) {
        localStorage.setItem("language", payload);
        state.language = payload;
        location.reload();
    },
};
