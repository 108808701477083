export class FeatureModel {
    id = null;
    title = '';
    description = '';

    constructor(item, language) {
        this._setId(item);
        this._setTitle(item, language);
        this._setDescription(item, language);
    }

    /**
     * Set id
     * @param id
     * @private
     */


    _setId({id}) {
        this.id = id;
    }


    /**
     * Set Title
     * @param item
     * @param language
     * @private
     */

    _setTitle(item, language) {
        const key = "title_" + language;
        this.title = item[key];
    }

    /**
     * Set description
     * @param item
     * @param language
     * @private
     */

    _setDescription(item, language) {
        const key = "description_" + language;
        this.description = item[key];
    }
}
