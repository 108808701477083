export class FinancialReportModel {
    id = null;
    title = '';
    description = '';
    date = '';
    download = '';

    constructor(item, language) {
        this._setId(item);
        this._setTitle(item, language);
        this._setDescription(item, language);
        this._setDate(item);
        this._setDownload(item);
    }

    /**
     * Set id
     * @param id
     * @private
     */


    _setId({id}) {
        this.id = id;
    }


    /**
     * Set Title
     * @param item
     * @param language
     * @private
     */

    _setTitle(item, language) {
        const key = "title_" + language;
        this.title = item[key];
    }

    /**
     * Set description
     * @param item
     * @param language
     * @private
     */

    _setDescription(item, language) {
        const key = "description_" + language;
        this.description = item[key];
    }

    /**
     * Set date
     * @param date
     * @private
     */

    _setDate({uploadDate}) {
        const date =  new Date(uploadDate);
        let day = date.getDate();
        let month = date.getMonth();
        let year = date.getFullYear();
        let hours = date.getHours();
        let minutes = date.getMinutes();
        if(day<10) {
            day = `0${day+1}`
        }
        if(month<10) {
            month = `0${month+1}`
        }
        if(hours<10) {
            hours = `0${hours+1}`
        }
        if(minutes<10) {
            minutes = `0${minutes+1}`
        }
        this.date = `${day}.${month}.${year}           ${hours}:${minutes}`;
    }
    /**
     * Set download
     * @param download
     * @private
     */

    _setDownload({fileUrl}) {
        this.download = fileUrl;
    }
}
