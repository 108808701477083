export class ContactModel {
    constructor(item) {
        this._setEmail(item);
        this._setLocation(item);
        this._setPhone(item);
    }

    /**
     * Set email
     * @param email
     * @private
     */

    _setEmail({email}) {
        this.email = email;
    }

    /**
     * Set location
     * @param location
     * @private
     */

    _setLocation({location}) {
        this.location = location;
    }

    /**
     * Set location
     * @param phone
     * @private
     */


    _setPhone({phone}) {
        this.phone = phone;
    }
}