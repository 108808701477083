import FooterMenuComponent from "@/components/layout/footer-menu/footer-menu.component.vue";
import FooterContactComponent from "@/components/layout/footer-contact/footer-contact.component.vue";
import FooterActionComponent from "@/components/layout/footer-action/footer-action.component.vue";

export default {
    name: 'footer-component',
    components: {
        FooterMenuComponent,
        FooterContactComponent,
        FooterActionComponent,
    },
    data() {
        return {
            text: 'FOOTER'
        }
    }
}