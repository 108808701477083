export class AboutsModel {
    id = null;
    aboutTypeId = '';
    description = '';

    constructor(item, language) {
        this._setId(item);
        this._setAboutTypeId(item);
        this._setDescription(item, language);
    }

    /**
     * Set id
     * @param id
     * @private
     */


    _setId({id}) {
        this.id = id;
    }


    /**
     * Set aboutTypeId
     * @param aboutTypeId
     * @private
     */


    _setAboutTypeId({aboutTypeId}) {
        this.aboutTypeId = aboutTypeId;
    }

    /**
     * Set description
     * @param item
     * @param language
     * @private
     */

    _setDescription(item, language) {
        const key = "description_" + language;
        this.description = item[key];
    }
}
