import axios from 'axios';
import api from '@/core/api';
import {AboutsModel} from '@/views/about/models/abouts.model';
import {WorkSkillsModel} from '@/views/about/models/work-skills.model';

export default {
    getAbout({commit}) {
        const language = localStorage.getItem("language");
        return axios
            .get(api.about)
            .then((res) => {
                commit('SET_ABOUT', res.data.abouts.items.map(item => {
                    return new AboutsModel(item, language);
                }));
                return true;
            })
            .catch(() => {
                return false;
            });
    },
    getWorkSkills({commit}) {
        const language = localStorage.getItem("language");
        return axios
            .get(api.workSkills)
            .then((res) => {
                commit('SET_WORK_SKILLS',
                    res.data.workSkills.items.map(item => {
                        return new WorkSkillsModel(item, language);
                    }));
                return true;
            })
            .catch(() => {
                return false;
            });
    },
};
