import HeaderComponent from './components/layout/header/header.component.vue'
import FooterComponent from './components/layout/footer/footer.component.vue'

export default {
    components: {
        HeaderComponent,
        FooterComponent,
    },
    created() {
        this.$store.dispatch('getLeads');
    }
}
