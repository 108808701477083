import {mapGetters, mapState} from "vuex";

export default {
    name: 'header-component',
    computed: {
        isPage() {
            return this.$route.name;
        },
        ...mapState({
            language: state => state.language,
        }),
        ...mapGetters({
            local: '_getLocal'
        }),
        menu() {
            return [
                {
                    id: 1,
                    name: this.local.header.mainPage,
                    link: 'home'
                },
                {
                    id: 2,
                    name: this.local.header.about,
                    link: 'about'
                },
                {
                    id: 3,
                    name:  this.local.header.project,
                    link: 'projects'
                },
                {
                    id: 4,
                    name:  this.local.header.contact,
                    link: 'contact'
                },

            ]
        }
    },
    data() {
        return {
            mobile: false
        }
    },

    methods: {

        toggleMobile() {
            this.mobile = !this.mobile;
            if (this.mobile) {
                document.body.style.overflow = 'hidden'
            } else {
                document.body.style.overflow = ''
            }
        },
        changeLanguage(e) {
            this.$store.commit('setLangManual', e.target.value);
        }
    },
    created() {
        this.$store.dispatch('getLanguage');
    },
}