<style src="./footer-menu.component.scss" lang="scss"/>
<template>
  <div class="footer-menu">
    <ul>
      <template v-for="item in menu">
        <li

            :key="item.id"
            class="footer-menu__item"
            v-if="item.id!==4"
        >
          <router-link :to="{name: item.link}">
            {{ item.name }}
          </router-link>
        </li>
        <li
            class="footer-menu__item"
            v-if="isPage==='about' && item.id===4" :key="item.id">
          <router-link :to="{name: item.link}">
            {{ item.name }}
          </router-link>
        </li>
      </template>

    </ul>
    <div class="footer-copyright">
      &copy; 2022 Marmar Construction. All rights reserved.
    </div>
  </div>

</template>
<script src="./footer-menu.component.js"/>