import axios from 'axios';
import api from '@/core/api';
import {ProjectsModel} from '@/views/projects/models/project.model';

export default {
    getProjects({commit}, data) {
        const language = localStorage.getItem('language');
        return axios
            .get(api.projects, {params: data})
            .then((res) => {
                console.log(res)
                commit('SET_PROJECTS', res.data.projects.items.map(item => {
                    console.log( new ProjectsModel(item, language))
                    return new ProjectsModel(item, language);
                }));
                commit('SET_PAGE_COUNT', res.data.projects.totalPages);
                return true;
            })
            .catch(() => {
                return false;
            });
    },
};
