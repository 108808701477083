export default {
    about: 'abouts',
    financialReports: 'financialreports',
    leads: 'leads',
    reportStatistics: 'reportstatistics',
    workSkills: 'workSkills',
    contacts: 'contacts',
    features: 'features',
    projects: 'projects',
}
