import {mapGetters} from 'vuex';

export default {
    name: 'footer-contact-component',
    computed: {
        ...mapGetters({
            contacts: '_getContact',
        }),
    },
    created() {
        this.$store.dispatch('getContacts');
    }
};
